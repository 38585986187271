<template>
  <svg
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="3.5"
      width="3"
      height="18"
      transform="rotate(-90 0 3.5)"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomRemoveIcon",
};
</script>
